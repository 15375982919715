import React, { FC } from "react";
interface Props {
  id: string;
  image: string;
  title: string;
  onClick?: () => void;
}

const Card: FC<Props> = ({ image, title, onClick, id }) => {
  return (
    <span id={id} className="card" onClick={onClick}>
      <img className="card-image" src={image} alt={title} />
      <div className="card-title">{title}</div>
    </span>
  );
};

export default Card;
