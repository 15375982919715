import React, { useEffect, useState } from "react";
import "@styles/global.css";
import ScreenSaver from "./ScreenSaver";
import Card from "@components/Card";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation } from "@reach/router";

const SCREENSAVER_DELAY_MS = 60000;

const Layout = () => {
  const [screensaver, setScreensaver] = useState(true);
  const [lastInteraction, setLastInteraction] = useState("");
  const [screen, setScreen] = useState("select");
  const [qrCodeDelay, setQrCodeDelay] = useState(0);

  const location = useLocation();  

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("message", function (e) {
        const data = e.data;
        if (data.qrCode === 60000) {
          setQrCodeDelay(60000)
        }
        if (data.qrCode === 0) {
          setQrCodeDelay(0)
        }
        
        if (data.iFrameMessage === "iframeClick")
          setLastInteraction(Math.random().toString());
      });
    }
  }, []);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setScreen("select");
      setScreensaver(true);
      setQrCodeDelay(0);
    }, SCREENSAVER_DELAY_MS + qrCodeDelay);
    return () => clearTimeout(timeout);
  }, [lastInteraction, qrCodeDelay]);

  useEffect(() => {
    deleteAllCookies();
  }, [screen]);

  function deleteAllCookies() {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
  }

  return (
    <div
      id="app-container"
      onClick={() => {
        setLastInteraction(Math.random().toString());
      }}
    >
      {/* Screensaver */}
      <ScreenSaver
        className={screensaver ? "screensaver" : "screensaver hidden"}
        screensaver={screensaver}
        onClick={() => setScreensaver(false)}
      />
      {/* Header */}
      <div id="header">
        <img id="logo" src="images/logo.png" alt="logo" />
        <AnimatePresence exitBeforeEnter initial={false}>
          {screen === "select" && (
            <motion.p
              key={screen}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="header-text"
            >
              Wählen Sie Ihren Produktfinder:
            </motion.p>
          )}
          {screen === "laptop-finder" && (
            <motion.p
              key={screen}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="header-text"
            >
              Notebook-Finder
            </motion.p>
          )}
          {screen === "mac-finder" && (
            <motion.p
              key={screen}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="header-text"
            >
              Apple Mac-Konfigurator
            </motion.p>
          )}
        </AnimatePresence>
        <AnimatePresence exitBeforeEnter initial={false}>
          <motion.span
            key={screen}
            className={`header-back ${screen !== "select" ? "active" : ""}`}
            onClick={() => {
              setScreen("select");
              setQrCodeDelay(0);
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            Zurück zur Übersicht
          </motion.span>
        </AnimatePresence>
      </div>
      <div className="content-contaienr">
        <AnimatePresence exitBeforeEnter initial={false}>
          {/* Content */}
          {screen === "select" && (
            <motion.div
              id="index-container"
              key={screen}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Card
                id="laptop-finder"
                image="images/laptop-image.png"
                title="Notebook-Finder"
                onClick={() => {
                  setScreen("laptop-finder");
                }}
              />
              <Card
                id="mac-finder"
                image="images/mac-image.png"
                title={"Apple\n  Mac-Konfigurator"}
                onClick={() => {
                  setScreen("mac-finder");
                }}
              />
            </motion.div>
          )}
          {screen === "laptop-finder" && (
            <motion.div
              id="index-container"
              key={screen}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <iframe
                key={screen}
                src={
                  process.env.NODE_ENV === "development" ||
                  location.pathname === "/"
                    ? `product-finders/laptop-finder.html`
                    : `product-finders-prod/laptop-finder.html`
                }
                width="100%"
                height="100%"
              />
            </motion.div>
          )}
          {screen === "mac-finder" && (
            <motion.div
              id="index-container"
              key={screen}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <iframe
                key={screen}
                src={
                  process.env.NODE_ENV === "development" ||
                  location.pathname === "/"
                    ? `product-finders/mac-finder.html`
                    : `product-finders-prod/mac-finder.html`
                }
                width="100%"
                height="100%"
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Layout;
