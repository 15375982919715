import React, { FC, useEffect, useState } from "react";
import { motion } from "framer-motion";
interface Props {
  onClick: () => void;
  className?: string;
  screensaver: boolean;
}

const ScreenSaver: FC<Props> = ({ className, screensaver, onClick }) => {
  const [flag, setFlag] = useState(true);
  const [position, setPosition] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setFlag(true);
      setPosition(position === 0 ? -239 : 0);
    }, 16500);
    return () => clearInterval(interval);
  }, [position]);

  useEffect(() => {
    const interval = setTimeout(() => {
      setFlag(false);
    }, 15000);
    return () => clearTimeout(interval);
  }, [flag]);

  const variants = {
    hidden: {
      opacity: [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
      transition: {
        duration: 16.5,
        ease: "easeInOut",
      },
    },
    visible: {
      opacity: [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
      transition: {
        duration: 16.5,
        ease: "easeInOut",
      },
    },
  };

  return (
    <motion.div
      id="screensaver-container"
      className={className}
      animate={{ opacity: screensaver ? 1 : 0 }}
      onClick={onClick}
    >
      <video src="videos/video.mp4" autoPlay loop muted></video>
        <motion.div
          id="touch-action-hint"
          variants={variants}
          animate={flag ? "visible" : "hidden"}
          style={{ translateY: position }}
        >
          <Icon />
          <span>Bitte Screen berühren</span>
        </motion.div>
    </motion.div>
  );
};

const Icon = () => (
  <svg
    width="42"
    height="48"
    viewBox="0 0 42 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="touch-action-hint-icon"
  >
    <path
      d="M19.5 27C18.6562 27 18 27.75 18 28.5V37.5C18 38.3438 18.6562 39 19.5 39C20.25 39 21 38.3438 21 37.5V28.5C21 27.75 20.25 27 19.5 27ZM25.5 27C24.6562 27 24 27.75 24 28.5V37.5C24 38.3438 24.6562 39 25.4062 39C26.1562 39 27 38.3438 27 37.5L26.9062 28.5C26.9062 27.75 26.25 27 25.5 27ZM35.25 18.9375C34.0312 17.3438 32.0625 16.3125 29.9062 16.3125H28.6875C28.3125 16.3125 27.9375 16.4062 27.6562 16.4062C26.4375 15.5625 25.0312 15 23.5312 15H22.4062V6.75C22.4062 3.09375 19.4062 0 15.6562 0C12 0 8.90625 3.09375 8.90625 6.75V22.5938C7.875 22.125 7.03125 22.125 6.46875 22.125C2.34375 22.125 0 25.5938 0 28.6875C0 30.0938 0.375 31.4062 1.21875 32.5312L8.625 42.375C11.25 45.9375 15.5625 48 19.9688 48H30.2812C30.8438 48 31.5 48 31.9688 47.8125C34.6875 47.25 37.0312 45.9375 38.8125 43.9688C40.875 41.9062 42 39.0938 42 36.0938V25.7812C42 22.0312 39 19.0312 35.25 18.9375ZM37.5 36.0938C37.5 37.875 36.75 39.6562 35.5312 40.9688C34.4062 42.1875 32.8125 43.0312 30.8438 43.4062C30.6562 43.5 30.4688 43.5 30.2812 43.5H19.9688C16.9688 43.5 14.0625 42.0938 12.1875 39.6562L4.875 29.9062C4.59375 29.5312 4.5 29.0625 4.5 28.6875C4.5 27.0938 5.8125 26.625 6.46875 26.625C6.9375 26.625 7.59375 26.7188 8.15625 27.4688L10.7812 30.9375C11.0625 31.3125 11.5312 31.5938 12 31.5938C13.0312 31.5938 13.5 30.5625 13.5 30.0938V6.75C13.5 5.53125 14.4375 4.5 15.6562 4.5C16.875 4.5 17.9062 5.53125 17.9062 6.75V19.0312C17.9062 19.6875 18.375 20.5312 19.4062 20.5312C20.5312 20.5312 20.625 19.5 22.2188 19.5H23.5312C25.5 19.5 25.2188 21.375 26.8125 21.375C27.5625 21.375 27.6562 20.8125 28.6875 20.8125H29.9062C32.4375 20.8125 31.5938 23.4375 33.75 23.4375H35.0625C36.375 23.4375 37.4062 24.4688 37.4062 25.7812V36.0938H37.5ZM31.5 27C30.6562 27 30 27.75 30 28.5V37.5C30 38.3438 30.6562 39 31.5 39C32.25 39 33 38.3438 33 37.5V28.5C33 27.75 32.25 27 31.5 27Z"
      fill="white"
    />
  </svg>
);

export default ScreenSaver;
